<template>
    <div class="container-activation">
        <section class="wrapper-activacion">

            <div class="wrapper-logo">
                <img class="logo-app-login"
                    src="../assets/images/logo-dembora-horiz.svg"
                    alt="Logo product">
            </div>

            <div v-if="isPendingToActivate">
                <p class="reset-title">{{ $t('activation.reset-title-account') }}</p>
                <p>{{ $t('activation.reset-text') }}</p>
                    <form @submit.prevent="onSubmit">

                        <div class="mdl-textfield mdl-js-textfield">
                            <label class="activation-label" for="password">{{ $t('activation.password-placeholder-recovery') }}</label>
                            <input class="mdl-textfield__input activation-input" type="password" v-model="password" id="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required>
                        </div>

                        <div class="mdl-textfield mdl-js-textfield">
                            <label class="activation-label" for="verify-password">{{ $t('activation.password-verify-placeholder-recovery') }}</label>
                            <input class="mdl-textfield__input activation-input" type="password" v-model="passwordVerification" id="verify-password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required>
                        </div>

                        <div class="wrapper-submit">
                            <button type="submit" value="Activate" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect">
                                {{ $t('recovery.send') }}
                            </button>
                        </div>
                    </form>
            </div>

            <div v-else>
                <p>{{ $t('recovery.account-confirmation') }}</p>
                <router-link class="mdl-navigation__link setting" :to="{ name: 'Login' }" exact>{{ $t('login.login')}}</router-link>
            </div>
            <vue-element-loading :active="show" spinner="bar-fade-scale" color="#FF6700"/>
        </section>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import VueElementLoading from 'vue-element-loading'

    export default {
        name: "AccountPassRecovery",
        data() {
            return {
                isPendingToActivate: true,
                token: '',
                email: '',
                password: '',
                passwordVerification: ''
            }
        },
        created() {
            this.token = this.$route.params.token
            this.email = this.$route.params.email
        },
        computed: {
            ...mapState({
                show: state => state.auth.requesting
            })
        },
        methods: {
            ...mapActions('auth', ['recoveryAccount']),
            async onSubmit() {
                if (this.password !== this.passwordVerification) {
                    this.$toast.error(this.$i18n.t('shared.password-mismatch'))
                    return
                }

                try {
                    await this.recoveryAccount({
                        email   : this.email,
                        token   : this.token,
                        password: this.password
                    })
                    this.isPendingToActivate = false
                } catch(error) {
                    this.$toast.error(this.$i18n.t(error.message))
                }
            }
        },
        components: { VueElementLoading }
    }
</script>
